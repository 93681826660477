import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from 'enums'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
	table: {
		_light: {
			color: 'blackAlpha.800',
		},
		_dark: {
			color: 'whiteAlpha.800',
		},
	},
	thead: {
		_light: {
			backgroundColor: ThemeColors.LIGHT_MODE.WHITE,
		},
		_dark: {
			backgroundColor: ThemeColors.LIGHT_MODE.BLACK,
		},
	},
	th: {
		_light: {
			borderColor: 'blackAlpha.200',
		},
		_dark: {
			borderColor: 'rgba(255, 255, 255, .05)',
		},
	},
	td: {
		_light: {
			borderColor: 'blackAlpha.200',
			color: 'blackAlpha.800',
		},
		_dark: {
			borderColor: 'rgba(255, 255, 255, .05)',
		},
	},
})

export const tableTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { size: 'sm' } })
