import { Button, HStack, Stack, Tag, Text, Tooltip } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { getPopoverDeviceDetails } from 'api/devices'
import { ChevronRightIcon, ForwardIcon } from 'icons/global'
import { Link } from 'react-router-dom'

const callState = {
	0: 'CONNECTING',
	1: 'CONNECTED',
	2: 'BUSY',
	3: 'DECLINED',
	4: 'OFFLINE',
	5: 'LEFT CALL',
	6: 'NOT ANSWERING',
	7: 'CANT CONNECT',
	8: 'DISRUPTED',
	9: 'RECONNECTING',
	10: 'DISCONNECTED',
	11: 'REMOVED',
}

export const DevicePopover = ({ serialNumber }) => {
	const { data, isLoading, isError, error } = useQuery({
		queryKey: ['device-info', serialNumber],
		enabled: !!serialNumber,
		queryFn: ({ signal }) => getPopoverDeviceDetails(serialNumber, signal),
		select: (data) => data.result,
	})

	if (isLoading) {
		return null
	}

	if (isError) return <Text>{error?.cause?.errorResponse?.message || error?.message}</Text>

	return (
		<Stack spacing='4'>
			<HStack justifyContent='space-between'>
				<Text textTransform='uppercase'>Name:</Text>
				<Text>{data.deviceName}</Text>
			</HStack>
			<HStack justifyContent='space-between'>
				<Text textTransform='uppercase'>Company :</Text>
				<Text>{data.deviceLocation ? data.deviceLocation.company.name : 'Unassigned'}</Text>
			</HStack>
			<HStack justifyContent='space-between'>
				<Text textTransform='uppercase'>Id :</Text>
				<Text>{data.deviceId}</Text>
			</HStack>
			<HStack justifyContent='space-between'>
				<Text textTransform='uppercase'>Serial :</Text>
				<Text> {data.serialNumber}</Text>
			</HStack>
			<HStack justifyContent='space-between'>
				<Text textTransform='uppercase'>Status :</Text>
				<Tag colorScheme={data.status ? 'green' : 'gray'}>{data.status ? 'Online' : 'Offline'}</Tag>
			</HStack>
			<HStack justifyContent='space-between'>
				<Text textTransform='uppercase'>Call State :</Text>
				<Tag colorScheme='green'>{callState[data.callState] || 'Unknown'}</Tag>
			</HStack>
			{!!data.deviceLocation && (
				<HStack flexWrap='wrap'>
					<Tooltip hasArrow label='Company' placement='top'>
						{data.deviceLocation.company.name}
					</Tooltip>
					{!!data.deviceLocation.healthSystem && (
						<>
							<ChevronRightIcon boxSize='4' />
							<Tooltip hasArrow label='Health System' placement='top'>
								{data.deviceLocation.healthSystem.name}
							</Tooltip>
						</>
					)}
					{!!data.deviceLocation.region && (
						<>
							<ChevronRightIcon boxSize='4' />
							<Tooltip hasArrow label='Region' placement='top'>
								{data.deviceLocation.region.name}
							</Tooltip>
						</>
					)}
					{!!data.deviceLocation.hospital && (
						<>
							<ChevronRightIcon boxSize='4' />
							<Tooltip hasArrow label='Hospital' placement='top'>
								{data.deviceLocation.hospital.name}
							</Tooltip>
						</>
					)}
					{!!data.deviceLocation.department && (
						<>
							<ChevronRightIcon boxSize='4' />
							<Tooltip hasArrow label='Department' placement='top'>
								{data.deviceLocation.department.name}
							</Tooltip>
						</>
					)}
					{!!data.deviceLocation.floor && (
						<>
							<ChevronRightIcon boxSize='4' />
							<Tooltip hasArrow label='Floor' placement='top'>
								{data.deviceLocation.floor.name}
							</Tooltip>
						</>
					)}
					{!!data.deviceLocation.room && (
						<>
							<ChevronRightIcon boxSize='4' />
							<Tooltip hasArrow label='Room' placement='top'>
								{data.deviceLocation?.room?.name}
							</Tooltip>
						</>
					)}
				</HStack>
			)}
			<Button
				size='sm'
				variant='outline'
				colorScheme='blue'
				as={Link}
				to={`/devices/${data.serialNumber}/${data.solHelloDeviceId}`}
				rightIcon={<ForwardIcon />}
				target='_blank'>
				Go to device
			</Button>
		</Stack>
	)
}
