import { Modal, ModalBody, ModalContent, ModalOverlay, Text, useColorMode, useToast } from '@chakra-ui/react'
import { getModalDevices } from 'api/devices'
import { dropdownStyleConfig } from 'globals/dropdownStyleConfig'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'

export const GlobalSearchModal = ({ isOpen, onClose }) => {
	let abortController = null
	const toast = useToast()
	const { colorMode } = useColorMode()
	const navigate = useNavigate()
	const initialRef = useRef(null)
	const finalRef = useRef(null)

	const getAsyncDevices = async (query) => {
		if (abortController) {
			abortController.abort()
		}

		abortController = new AbortController()
		const response = await getModalDevices(query, abortController.signal)
		if (response?.error) {
			return toast({ status: 'error', description: 'Something went wrong fetching devices' })
		}

		return response.data.map((device) => ({
			label: `${device.deviceName} - ${device.serialNumber}`,
			value: device.serialNumber,
			id: device.solHelloDeviceId,
		}))
	}

	const dropdownStyle = colorMode === 'dark' && dropdownStyleConfig

	return (
		<Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef} finalFocusRef={finalRef}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody m={0} p={0}>
					<AsyncSelect
						isClearable
						closeMenuOnSelect={false}
						placeholder='Search for devices...'
						styles={dropdownStyle}
						menuIsOpen
						loadOptions={getAsyncDevices}
						onChange={(e, actionMeta) => {
							if (actionMeta.action === 'select-option') {
								onClose()
								return navigate(`devices/${e.value}/${e.id}`)
							}
						}}
						components={{
							NoOptionsMessage: () => (
								<Text textAlign='center' fontSize='small' my={2}>
									Start typing to search...
								</Text>
							),
						}}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
