import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		borderRadius: '15px',
		_light: {
			color: 'blackAlpha.800',
			backgroundColor: '#FFFFFF',
			border: '1px solid rgba(52, 52, 52, 0.2)',
		},
		_dark: {
			backgroundColor: 'inherit',
			color: 'whiteAlpha.800',
		},
	},
})

export const cardTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { variant: 'outline' } })
