import { Box, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { SolaborateDarkIcon, SolaborateLightIcon } from 'icons/global'
import GlobalSearch from 'layout/GlobalSearch'
import { newTabs } from 'layout/routeTabs'
import { SidebarFooter } from 'layout/SidebarFooter'
import SidebarList from 'layout/SideBarList'
import { useAuth } from 'oidc-react'
import { useMemo } from 'react'

const Sidebar = () => {
	const { colorMode } = useColorMode()
	const {
		userData: { profile },
	} = useAuth()

	const authorizedRoutes = useMemo(() => {
		return newTabs.filter((item) =>
			item.roles.some((item) => (profile['sub.role'] ? profile['sub.role'].includes(item) : profile.role?.includes(item)))
		)
	}, [])

	const sidebarBackground = useColorModeValue('#F6F6F6', '#111111')
	const borderColor = useColorModeValue('#E2E8F0', '#FFFFFF29')

	const isDarkMode = colorMode === 'dark'

	return (
		<Stack borderRight={`1px solid ${borderColor}`} bg={sidebarBackground} justifyContent='space-between' width='260px'>
			<Box>
				<Stack p='4' spacing='4'>
					{isDarkMode ? <SolaborateDarkIcon /> : <SolaborateLightIcon />}
					<GlobalSearch />
				</Stack>
				<SidebarList tabs={authorizedRoutes} />
			</Box>
			<SidebarFooter />
		</Stack>
	)
}

export default Sidebar
