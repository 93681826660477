import { Input, InputGroup, InputLeftElement, useColorMode, useDisclosure } from '@chakra-ui/react'
import { GlobalSearchModal } from 'components'
import { SearchIcon } from 'icons/global'

const GlobalSearch = () => {
	const { colorMode } = useColorMode()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const isDarkMode = colorMode === 'dark'

	return (
		<>
			<InputGroup onClick={onOpen}>
				<Input
					cursor='pointer'
					readOnly
					rounded='lg'
					type='text'
					placeholder='Search'
					variant={isDarkMode ? 'filled' : 'white'}
					_focusVisible={{ border: '2px solid transparent' }}
				/>
				<InputLeftElement>
					<SearchIcon />
				</InputLeftElement>
			</InputGroup>
			{isOpen && <GlobalSearchModal isOpen={isOpen} onClose={onClose} />}
		</>
	)
}

export default GlobalSearch
