import { ChevronRightIcon } from '@chakra-ui/icons'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { capitalizeWord } from 'helpers'
import { Link, useLocation } from 'react-router-dom'

export const BreadcrumbComponent = () => {
	const location = useLocation()

	const pathnames = location.pathname.split('/').filter((path) => path && !/\d/.test(path))

	if (location.pathname === '/') return null

	return (
		<Breadcrumb my={3} separator={<ChevronRightIcon />}>
			<BreadcrumbItem>
				<BreadcrumbLink as={Link} to='/'>
					Home
				</BreadcrumbLink>
			</BreadcrumbItem>
			{pathnames.map((name, index, array) => (
				<BreadcrumbItem key={name} pointerEvents={index === array.length - 1 ? 'none' : 'all'}>
					<BreadcrumbLink as={Link} to={name}>
						{capitalizeWord(name)}
					</BreadcrumbLink>
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	)
}
