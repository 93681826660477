import { statAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(statAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		borderRadius: '15px',
		backgroundColor: 'inherit',
		p: 4,
		_light: {
			border: '1px solid rgba(52, 52, 52, 0.2)',
			backgroundColor: '#FFFFFF',
		},
		_dark: {
			border: '1px solid rgba(255, 255, 255, 0.16)',
		},
	},
})

export const statTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { variant: 'outline' } })
